export const state = () => ({
    muted: false,
    people: [
        {
            name: '蔡惠芳',
            job: '三軍總醫院  社工師',
            videoId: 'XN6XFm1sGko',
            action:{
                fb:'Cheer_Share_FB',
                line:'Cheer_Share_LINE'
            }
        },
        {
            name: '張詩吟',
            job: '馬偕醫院  護理長',
            videoId: 'rC6vmV3IWUE',
            action:{
                fb:'Beer_Share_FB',
                line:'Beer_Share_LINE'
            }
        },
        {
            name: '劉曉菁/OBA',
            job: '大德基金會  教育師 / 導盲犬協會  療癒犬',
            videoId: 'xGNpaiI-LFQ',
            action:{
                fb:'Oba_Share_FB',
                line:'Oba_Share_LINE'
            }
        },
        {
            name: '謝菊英',
            job: '台南老吾老老人之家  宗教師',
            videoId: 'tlFoeq1IL_M',
            action:{
                fb:'Amazing_Share_FB',
                line:'Amazing_Share_LINE'
            }
        },
        {
            name: '劉又瑄',
            job: '成大醫院  音樂治療師',
            videoId: 'OlOgy6p0u4c',
            action:{
                fb:'Baby_Share_FB',
                line:'Baby_Share_LINE'
            }
        },
        {
            name: '朱為民',
            job: '台中榮總  醫師',
            videoId: '8UjDYGLWwzk',
            action:{
                fb:'Concert_Share_FB',
                line:'Concert_Share_LINE'
            }
        },
        {
            name: '羅惠群',
            job: '馬偕醫院  心理師',
            videoId: 'V4jRzasMEbs',
            action:{
                fb:'Baseball_Share_FB',
                line:'Baseball_Share_LINE'
            }
        },
        {
            name: '德嘉法師',
            job: '大悲學苑  宗教師',
            videoId: 'YfPeqxv9jAM',
            action:{
                fb:'Monk_Share_FB',
                line:'Monk_Share_LINE'
            }
        },
        {
            name: '張淑蘭',
            job: '蘭嶼居護所  居家護理師',
            videoId: 'h21Ruf47Lhs',
            action:{
                fb:'Ocean_Share_FB',
                line:'Ocean_Share_LINE'
            }
        },
        {
            name: '陳怡如',
            job: '康泰基金會  全人關懷師',
            videoId: '4YKy8AtUkU4',
            action:{
                fb:'Qmay_Share_FB',
                line:'Qmay_Share_LINE'
            }
        }
    ],
})

export const getters = {
    getMute: state => {
        return state.muted
    },
    getPeople: state => {
        return state.people
    },
    getPeopleLength: state => {
        return state.people.length
    },
}

export const actions = {
    setMuteAction({ commit }, payload) {
        commit('setMute', payload)
    },
}

export const mutations = {
    setMute(state, payload) {
        state.muted = payload
    },
}
